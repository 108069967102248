import * as React from "react";

import { Page, PrimaryButton } from "@app/site";
import { CheckCircleIcon, HBox, VBox } from "@app/ui";
import { useSmallScreen } from "@app/ui/hooks";
import { useColorModeValue } from "@chakra-ui/color-mode";
import {
  Box,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  SimpleGrid,
  Stack,
  Text,
  VStack
} from "@chakra-ui/layout";
import { PageProps } from "gatsby";

export default function PricingPage(props: PageProps) {
  return (
    <Page>
      <VBox p={2}>
        <Heading my={8}>Pricing</Heading>
        {/* <Heading my={8} size={"md"}>
        Start using DeckedLink for free with a 7-day free trial
      </Heading> */}
        {/* <PriceGrid /> */}
        {/* <PricePlans /> */}
        <PriceGrid />

        <HBox>
          {/* <Text fontSize={"sm"} px={6} my={4}>
            Your purchase may be subject to sales tax and will automatically
            renew every month or year, depending on the payment plan you’ve
            chosen. All amounts shown are in USD.
          </Text> */}
          <Text fontSize={"sm"} px={6} my={4}>
            Your purchase may be subject to sales tax and will automatically
            renew every month. All amounts shown are in USD.
          </Text>
        </HBox>
      </VBox>
    </Page>
  );
}

const PriceGrid = () => {
  return (
    <SimpleGrid
      templateColumns={{
        // base: "1fr 1fr 1fr",
        sm: "1fr",
        md: "1fr 1fr",
        lg: "1fr 1fr 1fr",
      }}
      spacing={8}
      px={3}
    >
      {/* <Stack spacing={1}>TODO</Stack> */}
      {/* <VBox h={"400px"} bg={"red.300"} align={"center"}>
        Price 1
      </VBox> */}

      <PricePlan />
      <PricePlan />
      <PricePlan />
    </SimpleGrid>
  );
};

function PriceWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Box
      mb={4}
      //   shadow="base"
      borderWidth="2px"
      alignSelf={{ base: "center", lg: "flex-start" }}
      // borderColor={useColorModeValue("gray.200", "gray.500")}
      borderColor={useColorModeValue("gray.600", "gray.200")}
      borderRadius={"xl"}
    >
      {children}
    </Box>
  );
}

export const PricePlans = () => {
  return (
    <Box py={12}>
      {/* <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          Plans that fit your need
        </Heading>
        <Text fontSize="lg" color={"gray.500"}>
          Start with 14-day free trial. No credit card needed. Cancel at
          anytime.
        </Text>
      </VStack> */}
      <Stack
        direction={{ base: "column", md: "row" }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}
      >
        <PricePlan />
        <PricePlan />
        <PricePlan />
      </Stack>
    </Box>
  );
};

const PricePlan = () => {
  const { isSmallScreen } = useSmallScreen();
  return (
    <PriceWrapper>
      <Box py={4} px={12}>
        <Text fontWeight="500" fontSize="2xl">
          Hobby
        </Text>
        <HStack justifyContent={isSmallScreen ? "flex-start" : "center"}>
          <Text fontSize="3xl" fontWeight="600">
            $
          </Text>
          <Text fontSize="5xl" fontWeight="900">
            79
          </Text>
          <Text fontSize="3xl" color="gray.500">
            /month
          </Text>
        </HStack>
      </Box>
      <VStack
        bg={useColorModeValue("green.50", "gray.700")}
        py={4}
        borderBottomRadius={"xl"}
        align={isSmallScreen ? "flex-start" : "center"}
      >
        <List spacing={3} textAlign="start" px={isSmallScreen ? 5 : 12}>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            unlimited build minutes
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            Lorem, ipsum dolor.
          </ListItem>
          <ListItem>
            <ListIcon as={CheckCircleIcon} color="green.500" />
            5TB Lorem, ipsum dolor.
          </ListItem>
        </List>
        <Box w="100%" pt={7} px={isSmallScreen ? 3 : 12}>
          {/* <Button w="full" colorScheme="red" variant="outline">
            Start trial
          </Button> */}
          <PrimaryButton
            label={"Start trial"}
            to={"#"}
            w={isSmallScreen ? "80%" : null}
            px
          />
        </Box>
      </VStack>
    </PriceWrapper>
  );
};
